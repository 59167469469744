.thumb {
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 5px solid white;
  border-radius: 10px;
}

.thumb a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.thumb a:hover {
  cursor: pointer;
}

i {
  padding: 2px;
  transition: all 0.2s;
  font-size: 22px;
}

i:hover {
  cursor: pointer;
  opacity: 0.7;
}

.floating-model {
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
} 

.floating-model-body {
  background-color: white;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  left: 50%;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}