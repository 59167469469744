.hasImage:hover section {
    background-color: rgba(5, 5, 5, 0.4) !important;
}

.hasImage:hover button:hover {
    background: rgba(5, 5, 5, 0.45) !important;
}

#overlay p,
i {
    opacity: 0;
}

#overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
}

#overlay.draggedover p,
#overlay.draggedover i {
    opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
    color: #2b6cb0;
}

.section {
    box-shadow: none;
    border: none;
    flex-direction: column;
    align-items: center;
}

.border-dashed {
    width: 45vw !important;
    border-radius: 10px;
}

.border-light-blue-400 {
    border: 2px dashed var(--clr-light-blue);
}

.image-card {
    padding-top: 10px;
}

.image-card img {
    height: 80px;
    width: 144px;
}

.image-card .filename {
    visibility: hidden;
    color: white;
    z-index: 99;
}

.image-card:hover .filename {
    visibility: visible;
    color: blue;
    z-index: 99;
}

.image-card .filesize {
    visibility: hidden;
    color: white;
    z-index: 99;
    background-color: black;
}

.image-card:hover .filesize {
    visibility: visible;
    color: white;
    z-index: 99;
}

.image-card button {
    height: 24px;
    width: 24px;
    position: absolute;
    z-index: 13;
    font-size: medium;
    color: white;
    visibility: hidden;
}

.image-card:hover button {
    visibility: visible;
    opacity: 1;
}
