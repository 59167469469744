.login {

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #fff;
    overflow-y: scroll;
    
}

.login::before {
    top: 0;
    background: linear-gradient(5deg, rgb(255 255 255 / 0%) 30%, #002757);
}

.loginbody::after {
    bottom: 0;
    background: linear-gradient(-5deg, #001e63, rgba(0, 0, 0, 0) 70%);
}

.login .gallery {
    display: flex;
    gap: 1vw;
    max-width: 1200px;
    min-width: 500px;
    height: 100vh;
    transform: translate(5%, -25%) rotate(-6deg) rotateX(10deg) rotateY(20deg);
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.login .gallery_line {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    height: fit-content;
    animation: slide 80s linear infinite;
}

.login .gallery_line:nth-child(2) {
    animation-direction: reverse;
}

.login .gallery_line img {
    flex: 1 1 auto;
    width: 100%;
    object-fit: cover;
}

.login .Me {
    position: fixed;
    z-index: 15;
    bottom: 20px;
    left: 50%;
    color: #111;
    transform: translateX(-50%);
    font-weight: 700;
    text-align: center;
    opacity: 0.7;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
}

.login section {
    position: flex;
    max-width: 400px;
    background-color: transparent;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
    border: 0.0625rem solid rgb(222, 226, 230);
    border-radius: 20px;
    backdrop-filter: blur(55px);
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    z-index: 999;
}

.login section img {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.login h1 {
    font-size: 2rem;
    color: #000;
    text-align: center;
}

.login .inputbox {
    position: relative;
    margin: 30px 0;
    max-width: 310px;
    border-bottom: 2px solid #000;
}

.login .inputbox label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #000;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

.login input:focus~label,
.login input:valid~label {
    top: -5px;
}

.login .inputbox input {
    width: 100%;
    height: 60px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #000;
}

.login .inputbox ion-icon {
    position: absolute;
    right: 8px;
    color: #000;
    font-size: 1.2rem;
    top: 20px;
}

.login .forget {
    margin: 35px 0;
    font-size: 0.85rem;
    color: #000;
    display: flex;
    justify-content: space-between;

}

.login .forget label {
    display: flex;
    align-items: center;
}

.login .forget label input {
    margin-right: 3px;
    cursor: pointer;
}

.login .forget a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
}

.login .forget a:hover {
    text-decoration: underline;
}

.login button {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: rgb(68 197 255);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.4s ease;
}

.login button:hover {
    background-color: rgb(255, 255, 255, 0.5);
}

.login .register {
    font-size: 0.9rem;
    color: #000;
    text-align: center;
    margin: 25px 0 10px;
}

.login .register p a {
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.login .register p a:hover {
    text-decoration: underline;
}