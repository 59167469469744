
.overlay {
    width: 100%;
    height: 100%;
    overflow: auto;
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    transform: translateY(200px);
    transition: opacity 0.5s, transform 0.5s, z-index 0s 0.5s;
  }

  .overlay .overlay-background {
    background-color: rgb(26 35 53);
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  .overlay .overlay-content {
    width: 100% !important;
    max-width: 530px;
    margin: 100px auto 0 !important;
    padding: 50px 20px;
    color: #fff;
    position: relative;
    z-index: 10;
  }

  .overlay .overlay-content .overlay-close {
    display: block;
    font-size: 35px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
  }

  .overlay .overlay-content .overlay-close:hover {
    opacity: 0.8;
  }

  .overlay .overlay-content .blurb {
    margin-top: 35px;
    line-height: 100%;
    font-weight: 600;
    font-size: 18px;
  }

  .overlay .overlay-content .blurb-tagline {
    display: block;
    margin-top: 10px;
    line-height: 100%;
    font-size: 14px;
  }

  .overlay .overlay-content .signup-form {
    margin-top: 35px;
  }

  .overlay .overlay-content .signup-form label {
    line-height: 100%;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .overlay .overlay-content .signup-form input {
    background-color: transparent;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    padding: 0 8px;
    border: 1px solid rgba(254, 254, 254, 0.3);
    border-radius: 5px;
  }

  .overlay .overlay-content .signup-form input:hover,
  .overlay .overlay-content .signup-form input:focus {
    border-color: #fff;
  }

  .overlay .overlay-content .signup-form .submit-btn {
    width: 150px;
  }

.overlay .overlay-content{
    width: 150px;
  }

  .overlay.open {
    z-index: 15;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s, z-index 0s;
  }

.overlay.short .overlay-content {
    margin: 30px auto;
  }

  @media (max-width: 600px) {
    .overlay .overlay-content {
      margin: 25px auto;
    }
  }